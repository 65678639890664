import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  FloatingLabel,
  Toast,
} from "react-bootstrap";
import { BackButton } from "./common/common";
import { Navigate } from "react-router-dom";

const Settings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleUpdatePassword = () => {
    // Add password update logic here
    console.log("Updating password...");
    console.log("Current Password:", currentPassword);
    console.log("New Password:", newPassword);
    console.log("Confirm New Password:", confirmNewPassword);

    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      Navigate("/dashboard");
    }, 3000);
  };

  return (
    <Container style={{ width: "95%" }}>
      <Row className="justify-content-center mt-2">
        <Col sm={6}>
          <h5 className="text-left mt-2 mb-4">Update Password</h5>
          <Form>
            <Row className="mb-3">
              <FloatingLabel
                controlId="currentPassword"
                label="Current Password"
                className="px-1 w-100"
                id="password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                value={currentPassword}
                required
              >
                <Form.Control
                  type="password"
                  placeholder="Current Password"
                  className="shadow-none"
                />
              </FloatingLabel>
            </Row>

            <Row className="mb-3">
              <FloatingLabel
                controlId="newPassword"
                label="New Password"
                className="px-1 w-100"
                id="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="shadow-none"
                />
              </FloatingLabel>
            </Row>

            <Row className="mb-3">
              <FloatingLabel
                controlId="confirmNewPassword"
                label="Confirm New Password"
                className="px-1 w-100"
                id="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="shadow-none"
                />
              </FloatingLabel>
            </Row>
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="outline-dark" onClick={handleUpdatePassword}>
                Update
              </Button>
              <BackButton title="Cancel" variant="outline-danger" />
            </div>
          </Form>
        </Col>
      </Row>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        bg="success"
        text="white"
        className="w-75"
        style={{
          position: "absolute",
          bottom: 40,
          right: 45,
        }}
      >
        <Toast.Header closeButton={false}>
          <strong className="mx-auto">Password Updated</strong>
        </Toast.Header>
        <Toast.Body className="text-dark">
          Password has been updated successfully!
        </Toast.Body>
      </Toast>
    </Container>
  );
};

export default Settings;
