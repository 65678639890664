import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    // Your logout logic, clear sensitive information, update state, etc.

    // Manipulate the browser history
    window.history.replaceState(null, "", "/");
    navigate("/");
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!location.pathname.startsWith("/")) {
        event.returnValue =
          "You are logged out. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location.pathname]);

  return <div onClick={handleLogout}>Logout</div>;
};

export default Logout;
