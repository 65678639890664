import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import Logout from "./Logout";

const Header = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  if (isHome) {
    return null;
  }

  return (
    <>
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand as={Link} to="/dashboard">
            Auto Kraft
          </Navbar.Brand>
          <Nav className="ms-auto gap-1">
            <Nav.Link as={Link} to="/settings">
              Settings
            </Nav.Link>
            <Nav.Link as={Link} to="/">
              <Logout />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
