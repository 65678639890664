import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import HomePage from "./components/Dashboard/HomePage";
import Settings from "./components/Dashboard/Settings";
import Header from "./components/Dashboard/Header";
import CreateJob from "./components/Dashboard/CreateJob";
import JobDetails from "./components/Dashboard/JobDetails";

const App = () => {
  const dummyAPIData = [
    {
      jobNumber: "53023123123",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
    {
      jobNumber: "53023",
      customerName: "Test",
      customerAddress: "#1243",
      contactNumber: "8884592554",
      deliveryTime: "11:11 AM",
      modelNumber: "KA09 EA1327",
      engineNumber: "EASPORTS1327",
      chassisNumber: "BRB137223",
      kilometersCovered: "200",
      parts: [
        {
          partName: "Silencer ",
          quantity: "1",
          amount: "2500",
        },
      ],
      customerRequests: [
        {
          request: "Clean Properly",
        },
      ],
      serviceAdviserObservations: [
        {
          observation: "Change Engine Oil",
        },
      ],
    },
  ];

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route
            path="/dashboard"
            exact
            element={<HomePage jobData={dummyAPIData} />}
          />
          <Route path="/create-job" exact element={<CreateJob />} />
          <Route path="/settings" exact element={<Settings />} />
          <Route
            path="/dashboard/job-details/:jobNumber"
            element={<JobDetails jobData={dummyAPIData} />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
