import React, { useState } from "react";
import { Container, Form, Button, FloatingLabel, Table } from "react-bootstrap";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const CreateJob = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [engineNumber, setEngineNumber] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [kilometersCovered, setKilometersCovered] = useState("");
  const [errors, setErrors] = useState({});
  const [parts, setParts] = useState([]);
  const [partName, setPartName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const [customerRequests, setCustomerRequests] = useState([]);
  const [request, setRequest] = useState("");
  const [requestEditIndex, setRequestEditIndex] = useState(null);

  const [serviceAdviserObservations, setServiceAdviserObservations] = useState(
    []
  );
  const [observation, setObservation] = useState("");
  const [observationEditIndex, setObservationEditIndex] = useState(null);

  const isFormValid = () => {
    // Simple validation, you can add more complex validation logic as needed")
    return (
      customerName.trim() !== "" &&
      customerAddress.trim() !== "" &&
      contactNumber.trim() !== "" &&
      deliveryTime.trim() !== "" &&
      modelNumber.trim() !== "" &&
      engineNumber.trim() !== "" &&
      chassisNumber.trim() !== "" &&
      kilometersCovered.trim() !== ""
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setErrors({
        customerName:
          customerName.trim() === "" ? "Customer Name is required" : "",
        customerAddress:
          customerAddress.trim() === "" ? "Customer Address is required" : "",
        contactNumber:
          contactNumber.trim() === "" ? "Customer Number is required" : "",
        deliveryTime:
          deliveryTime.trim() === "" ? "Delivery Time is required" : "",
        modelNumber:
          modelNumber.trim() === "" ? "Model Number is required" : "",
        engineNumber:
          engineNumber.trim() === "" ? "Engine Number is required" : "",
        chassisNumber:
          chassisNumber.trim() === "" ? "chassis Number is required" : "",
        kilometersCovered:
          kilometersCovered.trim() === ""
            ? "kilometers Covered is required"
            : "",
      });
      return;
    }

    console.log("Form submitted:", {
      customerName,
      customerAddress,
      contactNumber,
      deliveryTime,
      modelNumber,
      engineNumber,
      chassisNumber,
      kilometersCovered,
    });

    const jsonData = {
      customerName,
      customerAddress,
      contactNumber,
      deliveryTime,
      modelNumber,
      engineNumber,
      chassisNumber,
      kilometersCovered,
      parts,
      customerRequests,
      serviceAdviserObservations,
    };

    const blob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "jobsData.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const addPart = () => {
    if (
      partName.trim() === "" ||
      quantity.trim() === "" ||
      amount.trim() === ""
    ) {
      setErrors({
        partName: partName.trim() === "" ? "Part Name is required" : "",
        quantity: quantity.trim() === "" ? "Quantity is required" : "",
        amount: amount.trim() === "" ? "Amount is required" : "",
      });
      return;
    }

    if (editIndex !== null) {
      // If editing, update the existing product
      const updatedParts = [...parts];
      updatedParts[editIndex] = { partName, quantity, amount };
      setParts(updatedParts);
      setEditIndex(null);
    } else {
      // If not editing, add a new product
      setParts([...parts, { partName, quantity, amount }]);
    }

    // Clear input fields
    setPartName("");
    setQuantity("");
    setAmount("");
    setErrors({});
  };

  const removePart = (index) => {
    const updatedParts = [...parts];
    updatedParts.splice(index, 1);
    setParts(updatedParts);
  };

  const editPart = (index) => {
    // Set the edit index and populate input fields with current values
    setEditIndex(index);
    const productToEdit = parts[index];
    setPartName(productToEdit.partName);
    setQuantity(productToEdit.quantity);
    setAmount(productToEdit.amount);
  };

  // Calculate total amount
  const totalPrice = parts.reduce((total, part) => {
    return total + parseFloat(part.amount) * parseFloat(part.quantity) || 0;
  }, 0);

  const addCustomerRequest = () => {
    if (request.trim() === "") {
      return;
    }

    if (requestEditIndex !== null) {
      // If editing, update the existing request
      const updatedRequests = [...customerRequests];
      updatedRequests[requestEditIndex] = { request };
      setCustomerRequests(updatedRequests);
      setRequestEditIndex(null);
    } else {
      // If not editing, add a new request
      setCustomerRequests([...customerRequests, { request }]);
    }

    // Clear input field
    setRequest("");
  };

  const removeCustomerRequest = (index) => {
    const updatedRequests = [...customerRequests];
    updatedRequests.splice(index, 1);
    setCustomerRequests(updatedRequests);
  };

  const editCustomerRequest = (index) => {
    // Set the edit index and populate input field with current value
    setRequestEditIndex(index);
    const requestToEdit = customerRequests[index];
    setRequest(requestToEdit.request);
  };

  const addObservation = () => {
    if (observation.trim() === "") {
      return;
    }

    if (observationEditIndex !== null) {
      // If editing, update the existing observation
      const updatedObservations = [...serviceAdviserObservations];
      updatedObservations[observationEditIndex] = { observation };
      setServiceAdviserObservations(updatedObservations);
      setObservationEditIndex(null);
    } else {
      // If not editing, add a new observation
      setServiceAdviserObservations([
        ...serviceAdviserObservations,
        { observation },
      ]);
    }

    // Clear input field
    setObservation("");
  };

  const removeObservation = (index) => {
    const updatedObservations = [...serviceAdviserObservations];
    updatedObservations.splice(index, 1);
    setServiceAdviserObservations(updatedObservations);
  };

  const editObservation = (index) => {
    // Set the edit index and populate input field with current value
    setObservationEditIndex(index);
    const observationToEdit = serviceAdviserObservations[index];
    setObservation(observationToEdit.observation);
  };

  return (
    <Container>
      <h5 className="mt-3 mb-4 fw-bold">Create New Job</h5>
      <Form className="mb-4" onSubmit={handleSubmit}>
        <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
          <h6 className="mt-2 mb-3 fst-italic">Customer Details</h6>
          <FloatingLabel
            controlId="customerName"
            label="Customer Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter customer name"
              value={customerName}
              onChange={(e) => {
                setCustomerName(e.target.value);
                setErrors({ ...errors, customerName: "" });
              }}
              className="shadow-none"
            />
            {errors.customerName && (
              <div className="text-danger">{errors.customerName}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="customerAddress"
            label="Customer Address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter customer address"
              value={customerAddress}
              onChange={(e) => {
                setCustomerAddress(e.target.value);
                setErrors({ ...errors, customerAddress: "" });
              }}
              className="shadow-none"
            />
            {errors.customerAddress && (
              <div className="text-danger">{errors.customerAddress}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="contactNumber"
            label="Contact Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter contact number"
              value={contactNumber}
              onChange={(e) => {
                setContactNumber(e.target.value);
                setErrors({ ...errors, contactNumber: "" });
              }}
              className="shadow-none"
            />
            {errors.contactNumber && (
              <div className="text-danger">{errors.contactNumber}</div>
            )}
          </FloatingLabel>
        </div>
        <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
          <h6 className="mt-2 mb-3 fst-italic">Vehicle Details</h6>
          <FloatingLabel
            controlId="deliveryTime"
            label="Delivery Time"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter delivery time"
              value={deliveryTime}
              onChange={(e) => {
                setDeliveryTime(e.target.value);
                setErrors({ ...errors, deliveryTime: "" });
              }}
              className="shadow-none"
            />
            {errors.deliveryTime && (
              <div className="text-danger">{errors.deliveryTime}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="modelNumber"
            label="Model Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter model number"
              value={modelNumber}
              onChange={(e) => {
                setModelNumber(e.target.value);
                setErrors({ ...errors, modelNumber: "" });
              }}
              className="shadow-none"
            />
            {errors.modelNumber && (
              <div className="text-danger">{errors.modelNumber}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="engineNumber"
            label="Engine Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter engine number"
              value={engineNumber}
              onChange={(e) => {
                setEngineNumber(e.target.value);
                setErrors({ ...errors, engineNumber: "" });
              }}
              className="shadow-none"
            />
            {errors.engineNumber && (
              <div className="text-danger">{errors.engineNumber}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="chassisNumber"
            label="Chassis Number"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter chassis number"
              value={chassisNumber}
              onChange={(e) => {
                setChassisNumber(e.target.value);
                setErrors({ ...errors, chassisNumber: "" });
              }}
              className="shadow-none"
            />
            {errors.chassisNumber && (
              <div className="text-danger">{errors.chassisNumber}</div>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="kilometersCovered"
            label="Kilometers Covered"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter kilometers covered"
              value={kilometersCovered}
              onChange={(e) => {
                setKilometersCovered(e.target.value);
                setErrors({ ...errors, kilometersCovered: "" });
              }}
              className="shadow-none"
            />
            {errors.kilometersCovered && (
              <div className="text-danger">{errors.kilometersCovered}</div>
            )}
          </FloatingLabel>
        </div>
        <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
          <h6 className="mt-2 mb-3 fst-italic">Customer Requests</h6>
          <FloatingLabel
            controlId="request"
            label="Customer Request"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter customer request"
              value={request}
              onChange={(e) => setRequest(e.target.value)}
              className="shadow-none"
            />
          </FloatingLabel>

          <Button className="mb-3 w-100" onClick={addCustomerRequest}>
            {requestEditIndex !== null ? "Update Request" : "Add Request"}
          </Button>
        </div>
        {customerRequests.length > 0 && (
          <>
            <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
              <h6 className="py-1">Customer Requests Table</h6>
              <Table striped bordered hover>
                <thead>
                  <tr className="text-center align-center">
                    <th>SL. No</th>
                    <th>Customer Request</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {customerRequests.map((request, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{request.request}</td>
                        <td>
                          <EditOutlinedIcon
                            className="me-2"
                            style={{ color: "rgb(53, 119, 212)" }}
                            onClick={() => editCustomerRequest(index)}
                          />
                          <DeleteForeverOutlinedIcon
                            onClick={() => removeCustomerRequest(index)}
                            style={{ color: "#f44336" }}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
          <h6 className="mt-2 mb-3 fst-italic">
            Service Adviser's Observations
          </h6>
          <FloatingLabel
            controlId="observation"
            label="Observation"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter observation"
              value={observation}
              onChange={(e) => setObservation(e.target.value)}
              className="shadow-none"
            />
          </FloatingLabel>

          <Button className="mb-3 w-100" onClick={addObservation}>
            {observationEditIndex !== null
              ? "Update Observation"
              : "Add Observation"}
          </Button>
        </div>
        {serviceAdviserObservations.length > 0 && (
          <>
            <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
              <h6 className="py-1">Service Adviser's Observations Table</h6>
              <Table striped bordered hover>
                <thead>
                  <tr className="text-center align-center">
                    <th>SL. No</th>
                    <th>Observation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {serviceAdviserObservations.map((observation, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{observation.observation}</td>
                        <td>
                          <EditOutlinedIcon
                            className="me-2"
                            style={{ color: "rgb(53, 119, 212)" }}
                            onClick={() => editObservation(index)}
                          />
                          <DeleteForeverOutlinedIcon
                            onClick={() => removeObservation(index)}
                            style={{ color: "#f44336" }}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
          <h6 className="mt-2 mb-3 fst-italic">Part Entries</h6>
          <FloatingLabel
            controlId="partName"
            label="Part Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Enter part name"
              value={partName}
              onChange={(e) => setPartName(e.target.value)}
              className="shadow-none"
            />
          </FloatingLabel>

          <FloatingLabel controlId="quantity" label="Quantity" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Enter quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="shadow-none"
            />
          </FloatingLabel>

          <FloatingLabel controlId="amount" label="Amount" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="shadow-none"
            />
          </FloatingLabel>

          <Button className="mb-3 w-100" onClick={addPart}>
            {editIndex !== null ? "Update Part" : "Add Part"}
          </Button>
        </div>
        {parts.length > 0 && (
          <>
            <div className="border p-2 bg-white shadow mb-3 bg-body border-1 border-dark rounded">
              <h6 className="py-1">Parts Table</h6>
              <Table striped bordered hover>
                <thead>
                  <tr className="text-center align-center">
                    <th>SL. No</th>
                    <th>Part Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Total Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {parts.map((part, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ width: "200px" }}>{part.partName}</td>
                      <td>{part.quantity}</td>
                      <td>{part.amount}</td>
                      <td>
                        {parseFloat(part.amount) * parseFloat(part.quantity)}
                      </td>
                      <td style={{ display: "flex" }}>
                        <EditOutlinedIcon
                          className="me-2"
                          style={{ color: "rgb(53, 119, 212)" }}
                          onClick={() => editPart(index)}
                        />
                        <DeleteForeverOutlinedIcon
                          onClick={() => removePart(index)}
                          style={{ color: "#f44336" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="mb-3">
                <strong>Total Parts Amount: {totalPrice.toFixed(2)}</strong>
              </div>
            </div>
          </>
        )}

        <Button
          type="submit"
          className="w-100"
          variant={!isFormValid() ? "dark" : "primary"}
          disabled={!isFormValid()}
        >
          Create Job Sheet
        </Button>
      </Form>
    </Container>
  );
};

export default CreateJob;
