import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

const BackButton = ({ title, variant }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Button variant={variant} onClick={handleGoBack}>
      {title || "Go Back"}
    </Button>
  );
};

export { BackButton };
