import React, { useState, useRef, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./LoginStyles.css";
import { Row } from "react-bootstrap";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

const LOGIN_URL = "https://reqres.in/api/login";

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errorRef = useRef();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg("");
  }, [email, password]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    // try {
    //   const response = await axios.post(
    //     LOGIN_URL,
    //     JSON.stringify({ email, password }),
    //     {
    //       header: {
    //         "Content-Type": "application/json",
    //       },
    //       withCredentials: true,
    //     }
    //   );
    //   console.log(JSON.stringify(response?.date));
    //   console.log(JSON.stringify(response));
    //   const accessToken = response?.data?.token;

    //   setAuth({ email, password, accessToken });
    //   setEmail("");
    //   setPassword("");
    //   setSuccess(true);
    // } catch (error) {
    //   if (!error?.response) {
    //     setErrorMsg("No Server Response");
    //   } else if (error.response?.status === 400) {
    //     setErrorMsg("Missing Email or Password");
    //   } else if (error.response?.status === 401) {
    //     setErrorMsg("Unauthorized");
    //   } else {
    //     setErrorMsg("Login Failed!! Please verify Email and Password");
    //   }
    //   errorRef.current.focus();
    // }
    setEmail("");
    setPassword("");
    setSuccess(true);
    navigate("/dashboard");
  };

  return (
    <>
      {success ? (
        <section>
          <h1>Logged In</h1>
        </section>
      ) : (
        <>
          <section className="container wrapper">
            <h1 className="text-center my-4">Auto Krafts</h1>
            <Form onSubmit={handleLoginSubmit} className="w-100">
              <Row className="mb-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className="px-1 w-100"
                  ref={userRef}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    className="shadow-none"
                  />
                </FloatingLabel>
              </Row>
              <Row className="mb-3">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Password"
                  className="px-1 w-100"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                >
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="shadow-none"
                  />
                </FloatingLabel>
              </Row>
              <Button variant="dark" className="w-100" type="submit">
                Submit
              </Button>
            </Form>
            <p
              className="text-danger pt-2"
              ref={errorRef}
              aria-live="assertive"
            >
              {errorMsg}
            </p>
          </section>
        </>
      )}
    </>
  );
};

export default Login;
