import React from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import html2pdf from "html2pdf.js";
import { WhatsappShareButton } from "react-share";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa6";

const JobDetails = ({ jobData }) => {
  const { jobNumber } = useParams();
  const selectedJob = jobData.find((job) => job.jobNumber === jobNumber);

  if (!selectedJob) {
    return <div>Job not found</div>;
  }

  const generatePdf = () => {
    const content = document.getElementById("job-details-content");
    const pdfOptions = {
      margin: 10,
      filename: "job-details.pdf",
      image: { type: "jpeg", quality: 0.98 },
    };
    html2pdf()
      .from(content)
      .set(pdfOptions)
      .outputPdf((pdf) => {
        console.log(pdf);
      });
  };

  return (
    <div id="job-details-content" className="container py-2">
      <h6 className="mb-4">
        Job Details - <strong>{jobNumber}</strong> of
        <strong> {selectedJob.customerName}</strong>
      </h6>
      <Card>
        <Card.Body>
          <Card.Title>Customer Information</Card.Title>
          <Card.Text>
            <strong>Customer Name:</strong> {selectedJob.customerName}
            <br />
            <strong>Contact Number:</strong> {selectedJob.contactNumber}
            <br />
            <strong>Customer Address:</strong> {selectedJob.customerAddress}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Job Information</Card.Title>
          <Card.Text>
            <strong>Delivery Time:</strong> {selectedJob.deliveryTime}
            <br />
            <strong>Model Number:</strong> {selectedJob.modelNumber}
            <br />
            <strong>Engine Number:</strong> {selectedJob.engineNumber}
            <br />
            <strong>Chassis Number:</strong> {selectedJob.chassisNumber}
            <br />
            <strong>Kilometers Covered:</strong> {selectedJob.kilometersCovered}
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="row my-4 mx-1 border border-secondary d-flex align-items-center rounded">
        <div className="col-6">
          <p className="m-0">Share this Job Details</p>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center gap-4">
          <WhatsappShareButton
            onClick={() => generatePdf()}
            separator=":: "
            url={`data:text/plain;base64,${btoa(
              "Message from Auto Krafts, Check out this job details"
            )}`}
            title="Message from Auto Krafts, Check out this job details"
            className="fs-1 text-success"
          >
            <FaWhatsapp className="mb-1" />
          </WhatsappShareButton>
          <a
            href={`mailto:?subject=Check%20out%20this%20job%20details&body=Check%20out%20this%20job%20details:%0A${window.location.href}`}
            className="fs-1 text-success"
          >
            <FaRegEnvelope className="mb-1" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
