import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ITEMS_PER_PAGE = 10;
const PAGES_TO_SHOW = 5;

const HomePage = ({ jobData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = jobData.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentData = jobData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationButtons = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(PAGES_TO_SHOW / 2));
    const endPage = Math.min(totalPages, startPage + PAGES_TO_SHOW - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(i)}
            disabled={currentPage === i}
          >
            {i}
          </button>
        </li>
      );
    }

    return pages;
  };

  return (
    <div className="container py-2">
      <div className="mt-2 mb-2">
        <h6>New Job Card </h6>
        <Button
          variant="dark"
          className="w-100 my-3"
          as={Link}
          to="/create-job"
        >
          Create Job
        </Button>
      </div>
      <div className="my-2">
        <h6>Job Lists</h6>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Job No</th>
              <th>Name</th>
              <th>Vehicle No</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((data, index) => (
              <tr key={index}>
                <td style={{ maxWidth: "10%" }}>
                  <Link to={`/dashboard/job-details/${data.jobNumber}`}>
                    {data.jobNumber}
                  </Link>
                </td>
                <td style={{ maxWidth: "50%" }}>{data.customerName}</td>
                <td style={{ maxWidth: "40%" }}>{data.engineNumber}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <nav aria-label="Page navigation" className="shadow-none">
          <ul className="pagination d-flex justify-content-center shadow-none">
            <li
              className={`page-item shadow-none ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link shadow-none"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
            </li>
            {renderPaginationButtons()}
            <li
              className={`page-item shadow-none ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link shadow-none"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default HomePage;
